body {
  overflow-x: hidden;
  color: #7a7a7a !important;
}

@keyframes show-nav {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes show-intro {
  0% {
    opacity: 0;
    transform: translateY(-200%);
  }
  75% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
    transform: translateY(0);
  }
  /* from {
      width: 0
    }

    to {
      width: 100%
    } */
}
.intro {
  /* overflow: hidden;
  white-space: nowrap;
    animation: 3s steps(50, end) show-intro; */
  animation: 2s ease-out 0s 1 show-intro;
}

.navbar-end a:hover {
  background-color: transparent !important;
}

.navbar-menu {
  animation: 1s ease-out 0s 1 show-nav;
}
.hamburger-pos {
  z-index: 32;
}

.menu-mobile {
  display: block;
  position: absolute;
  right: 0;
  width: 60%;
  z-index: 50;
  transition: right 0.3s;
  top: 0;
}
.menu {
  height: 100vh;
  padding-top: 70px;
}
.menu li {
  padding-top: 30px;
  font-size: larger;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.menu-mobile-hidden {
  top: 0;
  position: absolute;
  right: -100%;
  width: 60%;
  transition: right 0.3s;
}
.resume-btn {
  transform: translateY(25%);
}
.navbar-burger:hover {
  background-color: transparent !important;
}
.section {
  margin: 40px;
}
.section h1 {
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
}
.section .description {
  padding-top: 40px;
  max-width: 600px;
}
.profile-img {
  max-height: 500px;
}

.title-name {
  font-size: 2rem;
}

.title-role {
  font-size: 3rem;
}
@media screen and (min-width: 768px) {
  .title-name {
    font-size: 3rem;
  }
  .title-role {
    font-size: 4rem;
  }
  .grid-container {
    grid-template-columns: 1fr 1fr !important;
  }
}

@media screen and (min-width: 1023px) {
  .title-name {
    font-size: 4rem;
  }

  .title-role {
    font-size: 5rem;
  }
  .grid-container {
    grid-template-columns: 1fr 1fr 1fr !important;
  }
  .menu-mobile {
    display: none;
  }
}
.grid-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  column-gap: 10px;
  row-gap: 15px;
}
.grid-item {
  background-color: #223d6b;
  border-radius: 5px;
  cursor: pointer;
  height: 100%;
  padding: 30px;
  transition: 0.5s;
}
.grid-item h1 {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
.project-icons {
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
}

.project-text {
  height: 150px;
}

.hidden {
  overflow-y: hidden;
}
.footer-sec {
  background-color: #223d6b !important;
  border-radius: 4px;
}

.footer-icons {
  display: flex;
  justify-content: center;
  gap: 30px;
}

.box {
  background-color: #223d6b !important;
}
.go-up-link {
  position: fixed !important;
  bottom: 250px !important;
  right: 30px !important;
}
.visible {
  display: block !important;
}
.invisible {
  display: none !important;
}
.grid-item:hover {
  scale: 1.03;
  transition: 0.5s;
}
.fas:hover {
  scale: 1.53;
  transition: 0.5s;
}
.fas {
  transition: 0.5s;
}
.fab:hover {
  scale: 1.53;
  transition: 0.5s;
}

.fab {
  transition: 0.5s;
}

.link {
  display: inline-block;
  position: relative;
  color: #485fc7 !important;
}
.link:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #0087ca;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.link:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.navbar-end .resume-btn:hover {
  background: #00d1b2 !important;
}
.navbar-item img {
  max-height: 2.75rem !important;
}
